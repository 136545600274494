<div class="flex flex-row justify-content-center align-items-center">
  <p-card class="login w-full" header="{{ 'LOGIN.welcome' | translate }}">
    <ng-template pTemplate="header">
      <img
        alt="greeting"
        src="assets/images/greeting.svg"
        width="42px"
        height="43.65px"
      />
    </ng-template>

    <app-content-login></app-content-login>
  </p-card>
</div>
