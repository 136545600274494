import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TranslateModule } from '@ngx-translate/core';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-popup-error',
  standalone: true,
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    TranslateModule,
    NgOptimizedImage,
    CardModule,
    ButtonModule,
  ],
  templateUrl: './popup-error.component.html',
  styleUrls: ['./popup-error.component.scss'],
})
export class PopupErrorComponent implements OnInit {
  @Input() title: string = '';
  @Input() message: string = '';
  @Input() image: string = '';
  @Input() btnLabel: string = '';
  @Input() btnTxt: string = '';

  constructor(private cdr: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.cdr.detectChanges();
  }
}
