<!-- <div class="filter-destination">
  <div *ngFor="let destiny of listDestiny">
    <div class="link-destiny" (click)="destinySelection(destiny.label)">
      <i class="{{ destiny.type | icons }} mr-2"></i>
      <span>{{ destiny.label }}</span>
    </div>
  </div>
</div> -->

<div *ngIf="showResults" class="location-list">
  <div *ngIf="(zoneList$ | async).length > 0">
    <div class="title-list p-2 w-full flex flex-row align-items-center">
      <i class="fa-solid fa-location-dot color-link ml-2 mr-2"></i>
      <span class="header-list">{{
        'TRIP.destination.zones' | translate
      }}</span>
    </div>

    <ul class="zone-list p-2">
      <li
        *ngFor="let location of zoneList$ | async"
        (click)="selectResult(location)">
        <a class="item-location ml-2">
          <span class="sub-name"
            >{{ location.region }}, {{ location.country }}</span
          >
        </a>
      </li>
    </ul>
  </div>

  <div *ngIf="(cityList$ | async).length > 0">
    <div class="title-list p-2 w-full flex flex-row align-items-center">
      <i class="fa-solid fa-building color-link ml-2 mr-2"></i>
      <span class="header-list">{{
        'TRIP.destination.cities' | translate
      }}</span>
    </div>

    <ul class="zone-list p-2">
      <li
        *ngFor="let location of cityList$ | async"
        (click)="selectResult(location)">
        <a class="item-location ml-2">
          <span class="sub-name"
            >{{ location.name }}, {{ location.region }},
            {{ location.country }}</span
          >
        </a>
      </li>
    </ul>
  </div>

  <ul
    *ngIf="
      notFound &&
      (zoneList$ | async).length === 0 &&
      (cityList$ | async).length === 0
    "
    class="location-list">
    <p class="not-found">No se encontraron resultados</p>
  </ul>
</div>
