import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, withPreloading } from '@angular/router';
import {
  provideAnimations,
  BrowserAnimationsModule,
} from '@angular/platform-browser/animations';
import { MainComponent } from './app/main/main.component';
import { routes } from './app/app-routing.module';
import { importProvidersFrom, LOCALE_ID } from '@angular/core';
import {
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IMAGE_CONFIG } from '@angular/common';
import { quicklinkProviders, QuicklinkStrategy } from 'ngx-quicklink';
import { GoogleMapsModule } from '@angular/google-maps';
import { retryInterceptor } from './app/core/interceptors/auth.interceptor';
import { MessageService } from 'primeng/api';
import { LoggerAdapter } from './app/core/providers/infrastructure/adapters/logger-adapter';
import { LoggerService } from './app/core/providers/infrastructure/logger.service';
import { InternationalizationAdapter } from './app/core/providers/infrastructure/adapters/internationalization-adapter';
import { InternationalizationService } from './app/core/providers/infrastructure/internationalization.service';
import { StorageAdapter } from './app/core/providers/infrastructure/adapters/storage-adapter';
import { StorageService } from './app/core/providers/infrastructure/storage.service';

import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import fr from '@angular/common/locales/fr';
import it from '@angular/common/locales/it';
import de from '@angular/common/locales/de';
import pt from '@angular/common/locales/pt';
registerLocaleData(es);
registerLocaleData(fr);
registerLocaleData(it);
registerLocaleData(de);
registerLocaleData(pt);
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

bootstrapApplication(MainComponent, {
  providers: [
    MessageService,
    {
      provide: LoggerAdapter,
      useClass: LoggerService,
    },
    {
      provide: InternationalizationAdapter,
      useClass: InternationalizationService,
    },
    {
      provide: StorageAdapter,
      useClass: StorageService,
    },
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([retryInterceptor(2)])
    ),
    provideAnimations(),
    quicklinkProviders,
    provideRouter(routes, withPreloading(QuicklinkStrategy)),
    importProvidersFrom([
      HttpClientModule,
      TranslateService,
      GoogleMapsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      BrowserAnimationsModule,
    ]),
    {
      provide: IMAGE_CONFIG,
      useValue: {
        breakpoints: [16, 48, 96, 128, 384, 640, 750, 828, 1080, 1200, 1920],
      },
    },
  ],
}).catch(err => console.error(err));
