import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputNumberModule } from 'primeng/inputnumber';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { StorageService } from 'src/app/core/providers/infrastructure/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-travellers-inline-mobile',
  standalone: true,
  imports: [CommonModule, InputNumberModule, TranslateModule, FormsModule],
  templateUrl: './travellers-inline-mobile.component.html',
  styleUrls: ['./travellers-inline-mobile.component.scss'],
})
export class TravellersInlineMobileComponent implements OnInit {
  adultsInput: number = 0;
  kidsInput: number = 0;
  babiesInput: number = 0;
  travellers: string;
  @Output() selectionTravellers: EventEmitter<any> = new EventEmitter();

  public storage = inject(StorageService);
  ngOnInit(): void {
    this.storage.changeStorage$.subscribe(res => {
      if (res) {
        if (res.key == environment.storeKeys.DESTINATION) {
          let destination = JSON.parse(res.value);
          this.adultsInput = destination.adults;
          this.kidsInput = destination.kids;
          this.babiesInput = destination.babies;
        }
      }
    });
  }
  changeTravellers() {
    let textTravellers: string = '';
    if (this.adultsInput > 0) {
      textTravellers = 'Adults ' + this.adultsInput;
    }
    if (this.kidsInput > 0) {
      textTravellers = textTravellers + ' ' + 'Kids ' + this.kidsInput;
    }
    if (this.babiesInput > 0) {
      textTravellers = textTravellers + ' ' + 'Babies ' + this.babiesInput;
    }
    this.travellers = textTravellers;
    this.selectionTravellers.emit({
      travellers: this.travellers,
      adults: this.adultsInput,
      kids: this.kidsInput,
      babies: this.babiesInput,
    });
  }
}
