import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { CreditCard } from 'src/app/core/models/credit-card.model';
import { GeneralService } from '../../general.service';
@Injectable({
  providedIn: 'root',
})
export class StripeAPIService {
  customerIdSession$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public creditCardList$: BehaviorSubject<CreditCard[]> = new BehaviorSubject(
    []
  );

  constructor(
    private httpClient: HttpClient,
    private _generalService: GeneralService
  ) {}

  getListCard(): Promise<any> {
    let session = sessionStorage.getItem('session');
    if (session != null) {
      let customerId = JSON.parse(session).customer;
      this._generalService.showLoading$.next(true);
      return new Promise((resolve, reject) => {
        this.httpClient
          .get(
            `${environment.apiUrl}/payment/get-card-list/${customerId}/sources`
          )
          .subscribe({
            next: (res: any) => {
              let arrayCreditList = [];
              res.data.forEach((item) => {
                arrayCreditList.push({
                  id: item.id,
                  brand: item.brand,
                  number: item.last4,
                  expires: item.exp_month + '/' + item.exp_year,
                  name: item.name,
                  default: item.default,
                  icon:
                    item.icon == 2
                      ? 'assets/images/visa.svg'
                      : 'assets/images/logo-Mastercard 1.svg',
                });
              });
              this.creditCardList$.next(arrayCreditList);
              resolve(res.data);
            },
            error: (e) => {
              this._generalService.showLoading$.next(false);
              reject(e);
              console.error(e);
            },
            complete: () => {
              this._generalService.showLoading$.next(false);
            },
          });
      });
    } else {
      return null;
    }
  }

  choosePaymentDefault(card_id: string): Promise<any> {
    let uuid = sessionStorage.getItem('uuid');
    let model = { uuid, card_id };
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/payment/select-payment-default`, model)
        .subscribe({
          next: (res: any) => {
            resolve(res.data);
          },
          error: (e) => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  sendTokenCard(token_source: string): Promise<any> {
    let model = {
      customer_id: JSON.parse(sessionStorage.getItem('session')).customer,
      token_source,
    };
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/payment/token-card-customer`, model)
        .subscribe({
          next: (res) => {
            resolve(res);
          },
          error: (e) => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  deleteCard(card_id: string): Promise<any> {
    const customer_id = JSON.parse(sessionStorage.getItem('session')).customer;
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(
          `${environment.apiUrl}/payment/remove-credit-card/${customer_id}/card/${card_id}`
        )
        .subscribe({
          next: (res) => {
            resolve(res);
          },
          error: (e) => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }
}
