<div class="popup-view-price">
  <p-card class="" header="{{ title$ | async }}">
    <ng-template pTemplate="header"> </ng-template>
    <div
      class="col-12 flex flex-column justify-content-start align-items-center buttons"
    >
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'HOME.BUTTON.sign_in' | translate }}"
        class="p-button-rounded p-button-warning secondary color-black mb-2"
        (click)="_generalService.onLogin()"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'HOME.BUTTON.sign_up' | translate }}"
        class="p-button-rounded primary"
        (click)="_generalService.onSignup()"
      ></button>
    </div>
  </p-card>
</div>
