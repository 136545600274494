<div class="flex flex-row justify-content-center align-items-center">
  <p-card
    class="header-check-inbox"
    header="{{ 'SIGNUP.CHECK_INBOX.title' | translate }}"
  >
    <ng-template pTemplate="header">
      <img
        alt="check_inbox"
        src="assets/images/check_inbox.svg"
        width="85.4px"
        height="55.2px"
      />
    </ng-template>

    <div class="text-center subtitle mb-4">
      <p class="">{{ "SIGNUP.CHECK_INBOX.email_recived" | translate }}</p>
    </div>

    <div class="field">
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'GENERAL.btn_ok' | translate }}"
        class="p-button-rounded p-button-warning primary flex justify-content-center w-full"
        (click)="onContinue()"
      ></button>
    </div>

    <div class="text-center">
      <a class="color-black underline" (click)="sendNewCode()">
        {{ "SIGNUP.CHECK_INBOX.no_recived" | translate }}</a
      >
    </div>

    <ng-template pTemplate="footer"> </ng-template>
  </p-card>
</div>
