<div
  class="recover-pass flex flex-row justify-content-center align-items-center"
>
  <p-card class="card" header="{{ 'RECOVER.RECOVER_FORM.title' | translate }}">
    <form [formGroup]="recoverForm">
      <div class="field">
        <span class="p-input-icon-right">
          <input
            formControlName="email"
            type="text"
            pInputText
            placeholder="{{ 'GENERAL.email' | translate }}"
          />
        </span>
      </div>
    </form>
    <div class="terms mb-4">
      <span
        >{{ "RECOVER.RECOVER_FORM.agree" | translate
        }}<a>{{ "RECOVER.RECOVER_FORM.terms" | translate }}</a
        ><span>{{ "RECOVER.RECOVER_FORM.and" | translate }}</span
        ><a>{{ "RECOVER.RECOVER_FORM.policy" | translate }}</a></span
      >
    </div>

    <div class="field">
      <button
        pButton
        pRipple
        type="button"
        [disabled]="recoverForm.invalid"
        label="{{ 'GENERAL.btn_continue' | translate }}"
        class="p-button-rounded p-button-warning secondary color-black flex justify-content-center btn_continue"
        (click)="onContinue()"
      ></button>
    </div>
  </p-card>
</div>
