<!-- <app-public-nav-bar></app-public-nav-bar> -->
<!-- <div
  class="set-new-pass flex flex-row justify-content-center align-items-center min-h-screen min-w-screen"> -->
<div class="flex flex-row justify-content-center align-items-center">
  <p-card class="card" header="{{ 'RECOVER.SET_NEW_PASS.title' | translate }}">
    <form [formGroup]="newPassForm">
      <div class="field">
        <span class="p-input-icon-right">
          <input
            class="color-black-23"
            formControlName="pass"
            type="password"
            pInputText
            placeholder="{{ 'RECOVER.SET_NEW_PASS.new' | translate }}"
            (keyup)="onChange($event)" />
        </span>
      </div>
      <div
        *ngIf="f['pass'].errors && f['pass'].touched"
        class="invalid-feedback mb-2">
        <div *ngIf="f['pass'].errors['required']">
          {{ 'GENERAL.ERRORS.pass_required' | translate }}
        </div>
        <div *ngIf="f['pass'].errors['pattern']">
          {{ 'GENERAL.ERRORS.pattern_password' | translate }}
        </div>
      </div>

      <div class="field">
        <span class="p-input-icon-right">
          <input
            class="color-black-23"
            formControlName="rePass"
            type="password"
            pInputText
            placeholder="{{ 'RECOVER.SET_NEW_PASS.repeat' | translate }}"
            (keyup)="onChange($event)" />
        </span>
      </div>

      <div
        *ngIf="f['rePass'].errors && f['rePass'].touched"
        class="invalid-feedback">
        <div *ngIf="f['rePass'].errors['required']">
          {{ 'GENERAL.ERRORS.confirm_pass_required' | translate }}
        </div>
      </div>
      <div *ngIf="!passConfirm && newPassForm.errors" class="invalid-feedback">
        <div *ngIf="newPassForm.errors['passConfirm']">
          {{ 'GENERAL.ERRORS.distinct_pass' | translate }}
        </div>
      </div>
    </form>

    <div class="field mt-4">
      <button
        pButton
        pRipple
        [disabled]="newPassForm.invalid"
        type="button"
        label="{{ 'GENERAL.btn_update' | translate }}"
        class="btn_update p-button-rounded p-button-warning secondary color-black flex justify-content-center"
        (click)="onUpdate()"></button>
    </div>
  </p-card>
</div>
