import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-last-search-destination',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './last-search-destination.component.html',
  styleUrls: ['./last-search-destination.component.scss'],
})
export class LastSearchDestinationComponent {
  lastSerachs: any = [
    {
      location: 'Barcelona, Spain',
      date: '20-23 dec',
      guestNumbers: '2 adults/ 1 kid',
    },
    {
      location: 'Madrid, Spain',
      date: '02-16 jul',
      guestNumbers: '2 adults',
    },
    {
      location: 'Zaragoza, Spain',
      date: '17-30 agu',
      guestNumbers: '2 adults',
    },
  ];
}
