<p-galleria
  [value]="medias"
  [showIndicators]="true"
  [showThumbnails]="false"
  [changeItemOnIndicatorHover]="true"
  [responsiveOptions]="responsiveOptions"
  [containerStyle]="{ 'max-width': '640px' }"
  [showItemNavigators]="true">
  <ng-template pTemplate="item" let-item>
    <img
      [src]="item.url"
      style="display: block"
      alt="item"
      width="433"
      height="263"
      class="img-gallery" />
  </ng-template>
</p-galleria>
