<div class="room-payment-resume card bg-light p-4 border-round-3xl" *ngIf="membership">
    <div class="price-detail">
        <h5 class="title mb-1">
            {{'ACCOMODATIONS.hotel_confirmation.your_price_details' | translate}}
        </h5>

        <div class="flex flex-column mt-3">
            <div class="flex flex-row justify-content-between align-items-center my-2">
                <div class="flex flex-column">
                    <p class="text color-primary size-sm">{{membership.profile_name}}</p>
                    <p class="text color-radio-color size-sm">1 year of membership
                    </p>
                    <p class="subtext color-light-dark size-sm">${{membership.profile_cost /
                        12}} / month</p>
                </div>
                <div>
                    <h5 class="color-dark-dark">${{membership.profile_cost}}</h5>
                </div>
            </div>

            <hr />

            <div class="flex flex-row justify-content-between align-items-center">
                <p class="text color-dark size-sm">
                    {{'ACCOMODATIONS.hotel_confirmation.total' | translate }}</p>
                <h4 class="color-primary">${{membership.profile_cost}}</h4>
            </div>

        </div>
    </div>
</div>