import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignupService {
  showCheckYourInbox$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  showCompleteData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  saveEmail$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {}
}
