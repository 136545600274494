import { Component, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CardModule } from 'primeng/card';
import { TranslateModule } from '@ngx-translate/core';
import { GeneralService } from 'src/app/core/providers/general.service';
import { ButtonModule } from 'primeng/button';
import { Observable } from 'rxjs';
import { FlowService } from 'src/app/core/providers/trip/flow.service';

@Component({
  selector: 'app-popup-view-price',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    TranslateModule,
    NgOptimizedImage,
    ButtonModule
  ],
  templateUrl: './popup-view-price.component.html',
  styleUrls: ['./popup-view-price.component.scss']
})

export class PopupViewPriceComponent implements OnInit {
  title$: Observable<string>;

  constructor(
    public _generalService: GeneralService,
    private _flowService: FlowService
  ) {
    this.title$ = this._flowService.titlePopupViewPrice$;
  }

  ngOnInit(): void {
  }
}
