<div class="popup_trip_canceled">
  <p-card class="" header="">
    <ng-template pTemplate="header">
      <div class="">
        <img
          alt="cancel"
          ngSrc="./assets/images/trip_canceled.png"
          width="85"
          height="103"
        />
      </div>
      <h6 class="m-0 title">
        {{ "ACCOMODATIONS.hotel_confirmation.reserve_cancelled" | translate }}
      </h6>
      <p class="subtitle">
        {{
          "ACCOMODATIONS.hotel_confirmation.txt_reserve_cancelled" | translate
        }}
      </p>
    </ng-template>
    <div
      class="col-12 flex flex-column justify-content-start align-items-center buttons"
    >
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'HOME.BUTTON.new_trip' | translate }}"
        class="p-button-rounded p-button-warning secondary mb-2"
        (click)="goToNewTrip()"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'HOME.BUTTON.go_to_dashboard' | translate }}"
        class="p-button-rounded primary"
        (click)="goToDashboard()"
      ></button>
    </div>
  </p-card>
</div>
