import { Component, Input, OnInit, inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarPrivateService } from 'src/app/core/providers/private/sidebar-private/sidebar-private.service';
import { ContentSidebarProfileComponent } from '../../../private/profile/shared-profile/sidebar-profile/content-sidbar-profile/content-sidebar-profile/content-sidebar-profile.component';
import { RouterModule } from '@angular/router';
import { GeneralService } from 'src/app/core/providers/general.service';
import { VoyagersService } from 'src/app/core/providers/private/voyagers/voyagers.service';
import { Observable } from 'rxjs';
import { ISession } from '../../../private/dashboard/dashboard.component';
import { DropdownModule } from 'primeng/dropdown';
import { LangOption } from 'src/app/core/models/lang.model';
import { environment } from 'src/environments/environment';
import { FormsModule } from '@angular/forms';
import { InternationalizationAdapter } from 'src/app/core/providers/infrastructure/adapters/internationalization-adapter';
import { LanguageSelectorComponent } from '../../shared-general/language-selector/language-selector.component';

@Component({
  selector: 'app-sidebar-private',
  standalone: true,
  imports: [
    CommonModule,
    SidebarModule,
    ButtonModule,
    TranslateModule,
    NgOptimizedImage,
    RouterModule,
    ContentSidebarProfileComponent,
    DropdownModule,
    FormsModule,
    LanguageSelectorComponent,
  ],
  templateUrl: './sidebar-private.component.html',
  styleUrls: ['./sidebar-private.component.scss'],
})
export class SidebarPrivateComponent implements OnInit {
  @Input() showSidebar: boolean = false;
  session$: Observable<ISession>;
  langOptions: LangOption[] = environment.selectLanguages;
  selectedLang: LangOption;
  private interLang = inject(InternationalizationAdapter);

  constructor(
    public _sidebarPrivateService: SidebarPrivateService,
    private _generalService: GeneralService,
    private _voyagersService: VoyagersService
  ) {
    this._sidebarPrivateService.showSidebarPrivate$.subscribe(result => {
      this.showSidebar = result;
      this._sidebarPrivateService.isSidebarPrivate$.next(result);
    });
    this.session$ = this._voyagersService.voyagerSession$;
  }

  ngOnInit(): void {
    this._voyagersService.getVoyagerInfoSession();
  }

  onShow(): void {
    this._sidebarPrivateService.isSidebarPrivate$.next(true);
  }

  onHide(): void {
    this._sidebarPrivateService.isSidebarPrivate$.next(false);
  }

  onLogout() {
    this.showSidebar = false;
    this._generalService.onLogout();
  }
}
