import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { HotelDetails } from 'src/app/core/models/hotel-details.model';
import { GalleriaModule } from 'primeng/galleria';

@Component({
  selector: 'app-gallery-hotel',
  standalone: true,
  imports: [CommonModule, GalleriaModule],
  templateUrl: './gallery-hotel.component.html',
  styleUrls: ['./gallery-hotel.component.scss'],
})
export class GalleryHotelComponent {
  @Input() medias: any;
  public responsiveOptions: any = [
    {
      breakpoint: '1200px',
      numVisible: 4,
      numScroll: 4,
    },
    {
      breakpoint: '1024px',
      numVisible: 2.9,
      numScroll: 3,
    },
    {
      breakpoint: '768px',
      numVisible: 1.9,
      numScroll: 2,
    },
    {
      breakpoint: '560px',
      numVisible: 1.1,
      numScroll: 1,
    },
  ];
}
