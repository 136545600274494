import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-payment-product-detail',
  standalone: true,
  imports: [CommonModule, ProgressSpinnerModule, TranslateModule],
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent {
  @Input() membership: any;

  constructor() {}
}
