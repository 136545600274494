import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarLoginSignupService } from 'src/app/core/providers/public/sidebar-login-signup/sidebar-login-signup.service';
import { ContentLoginComponent } from '../login/content-login/content-login.component';
import { ContentSignupFormComponent } from '../sign-up/signup-form/content-signup-form/content-signup-form.component';

@Component({
  selector: 'app-sidebar-login-signup',
  standalone: true,
  imports: [
    CommonModule,
    SidebarModule,
    ButtonModule,
    InputTextModule,
    TranslateModule,
    ContentLoginComponent,
    ContentSignupFormComponent,
  ],
  templateUrl: './sidebar-login-signup.component.html',
  styleUrls: ['./sidebar-login-signup.component.scss'],
})
export class SidebarLoginSignupComponent {
  @Input() showSidebar: boolean = false;

  constructor(public _sidebarLoginSignupService: SidebarLoginSignupService) {
    this._sidebarLoginSignupService.showSidebar$.subscribe((result) => {
      this.showSidebar = result;
    });
  }

  onShow(): void {
    this._sidebarLoginSignupService.isSidebar$.next(true);
  }

  onHide(): void {
    this._sidebarLoginSignupService.isLogin$.next(true);
    this._sidebarLoginSignupService.isSidebar$.next(false);
  }

  toggle(): void {
    this._sidebarLoginSignupService.isLogin$.next(
      !this._sidebarLoginSignupService.isLogin$.value
    );
  }
}
