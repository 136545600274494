<p-sidebar
  [(visible)]="showSidebar"
  (onHide)="onHide()"
  (onShow)="onShow()"
  position="right"
  styleClass="w-23rem">
  <ng-template pTemplate="header">
    <div
      class="flex flex-row align-items-center justify-content-between w-full">
      <div class="bg-gray p-1 circle" *ngIf="!(session$ | async).avatar">
        <span>{{ (session$ | async).shortName }}</span>
      </div>

      <div class="avatar-photo" *ngIf="(session$ | async).avatar">
        <img src="{{ (session$ | async).avatar }}" alt="profile-photo" />
      </div>

      <div>
        <p class="color-light-dark font-normal text-sm">
          {{ (session$ | async).membership }}
        </p>
        <p class="font-normal text-sm">{{ (session$ | async).name }}</p>
        <p class="color-light-dark font-normal text-sm">
          {{ (session$ | async).email }}
        </p>
      </div>
      <div></div>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <app-content-sidebar-profile
      class="flex flex-column"></app-content-sidebar-profile>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="sidebar-navigation-help flex flex-row mb-6">
      <a
        [routerLink]="['/help']"
        class="flex flex-row align-items-center justify-content-start color-secondary text-capitalize my-2">
        <div
          class="optimizer-image flex flex-column align-items-center justify-content-center mr-2">
          <i class="fa-solid fa-question icon-help"></i>
        </div>
        <span class="ml-1">{{ 'NAVIGATION.help' | translate }}</span>
      </a>
    </div>
    <app-language-selector></app-language-selector>

    <button
      pButton
      pRipple
      (click)="onLogout()"
      class="p-button-outlined p-button-danger flex flex-row justify-content-center align-items-center btn-logout">
      <i class="pi pi-sign-out mr-2"></i
      ><span>{{ 'GENERAL.btn_log_out' | translate }}</span>
    </button>
  </ng-template>
</p-sidebar>
