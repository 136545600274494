import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { ContentSignupFormComponent } from './content-signup-form/content-signup-form.component';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'app-signup-form',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    TranslateModule,
    ContentSignupFormComponent
  ],
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss']
})
export class SignupFormComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
