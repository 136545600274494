import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { GeneralService } from 'src/app/core/providers/general.service';

@Component({
  selector: 'app-new-payment-modal',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    NgOptimizedImage,
    TranslateModule,
    DialogModule,
    ButtonModule,
    CardModule,
  ],
  templateUrl: './new-payment-modal.component.html',
  styleUrls: ['./new-payment-modal.component.scss'],
})
export class NewPaymentModalComponent {
  @Input() visible: boolean = false;
  @Input() title: string = '';

  @Output() upgradeEmitter: EventEmitter<any> = new EventEmitter();

  public isPrivateView = false;
  constructor(private _generalService: GeneralService) {}

  public upgradeAccount() {
    this.upgradeEmitter.emit();
  }

  closePopup(): void {
    this._generalService.showPopupUpgrade$.next(false);
  }
}
