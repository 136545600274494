import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsPipes } from 'src/app/core/directives/icons.directive';
import { CitiesSearch } from 'src/app/core/models/cities.model';
import { Observable } from 'rxjs';
import { DestinationService } from 'src/app/core/providers/public/destination/destination.service';
import { TranslateModule } from '@ngx-translate/core';
import { FlowService } from 'src/app/core/providers/trip/flow.service';
import { StorageAdapter } from 'src/app/core/providers/infrastructure/adapters/storage-adapter';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-filter-destinations',
  standalone: true,
  imports: [CommonModule, IconsPipes, TranslateModule],
  templateUrl: './filter-destinations.component.html',
  styleUrls: ['./filter-destinations.component.scss'],
})
export class FilterDestinationsComponent implements OnInit {
  @Output() selectionDestiny: EventEmitter<any> = new EventEmitter();
  @Input() showResults: boolean;
  public zoneList$: Observable<CitiesSearch[]>;
  public cityList$: Observable<CitiesSearch[]>;
  public notFound: boolean = false;
  destinySelection(event): void {
    this.selectionDestiny.emit(event);
  }
  private storage = inject(StorageAdapter);

  constructor(
    private _destinationService: DestinationService,
    private _flowService: FlowService
  ) {
    this.zoneList$ = this._destinationService.zoneSearchs$;
    this.cityList$ = this._destinationService.citiesSearchs$;
  }

  ngOnInit(): void {}

  selectResult(destiny: any) {
    const selectedItem = destiny?.name ? destiny?.name : destiny?.region;
    this.showResults = false;
    this._destinationService.destinySelected$.next(destiny);
    this.storage.set(
      environment.storeKeys.SELECTED_DESTINY,
      JSON.stringify(destiny)
    );
    this.selectionDestiny.emit(destiny);
  }
}
