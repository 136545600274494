<div class="select-travels">
  <div class="" tabindex="0">
    <div class="flex flex-row">
      <div class="col-6 mr-2">
        <h6 class="title-travels">
          {{ 'SHARED.DESTINATION.adults' | translate }}
        </h6>
        <h6 class="subtitle-travels">
          {{ 'SHARED.DESTINATION.more13' | translate }}
        </h6>
      </div>
      <div class="col-6 flex justify-content-center">
        <div class="">
          <p-inputNumber
            #adults
            [showButtons]="true"
            buttonLayout="horizontal"
            inputId="horizontal"
            [(ngModel)]="adultsInput"
            spinnerMode="horizontal"
            [step]="1"
            incrementButtonIcon="pi pi-plus"
            [min]="0"
            class="input-key flex align-items-center"
            decrementButtonIcon="pi pi-minus"
            decrementButtonClass="p-button-min"
            incrementButtonClass="p-button-plus"
            (onInput)="changeTravellers()">
          </p-inputNumber>
        </div>
      </div>
    </div>
    <div class="flex flex-row">
      <div class="col-6 mr-2">
        <h6 class="title-travels">
          {{ 'SHARED.DESTINATION.kids' | translate }}
        </h6>
        <h6 class="subtitle-travels">
          {{ 'SHARED.DESTINATION.less13' | translate }}
        </h6>
      </div>
      <div class="col-6 flex justify-content-center">
        <div class="">
          <p-inputNumber
            #kids
            [showButtons]="true"
            buttonLayout="horizontal"
            inputId="horizontal"
            [(ngModel)]="kidsInput"
            spinnerMode="horizontal"
            [step]="1"
            incrementButtonIcon="pi pi-plus"
            [min]="0"
            class="input-key"
            decrementButtonIcon="pi pi-minus"
            decrementButtonClass="p-button-min"
            incrementButtonClass="p-button-plus"
            (onInput)="changeTravellers()">
          </p-inputNumber>
        </div>
      </div>
    </div>
    <div class="flex flex-row">
      <div class="col-6 mr-2">
        <h6 class="title-travels">
          {{ 'SHARED.DESTINATION.babies' | translate }}
        </h6>
        <h6 class="subtitle-travels">
          {{ 'SHARED.DESTINATION.less2' | translate }}
        </h6>
      </div>
      <div class="col-6 flex justify-content-center">
        <div class="">
          <p-inputNumber
            #babies
            [showButtons]="true"
            buttonLayout="horizontal"
            inputId="horizontal"
            [(ngModel)]="babiesInput"
            spinnerMode="horizontal"
            [step]="1"
            incrementButtonIcon="pi pi-plus"
            [min]="0"
            class="input-key"
            decrementButtonIcon="pi pi-minus"
            decrementButtonClass="p-button-min"
            incrementButtonClass="p-button-plus"
            (onInput)="changeTravellers()">
          </p-inputNumber>
        </div>
      </div>
    </div>
  </div>
</div>
