<p-toast></p-toast>
<div>
  <app-signup-form
    *ngIf="!_signupService.showCheckYourInbox$.value"
    (toastEmitter)="showMessage($event)"></app-signup-form>
  <app-check-your-inbox
    *ngIf="_signupService.showCheckYourInbox$.value"></app-check-your-inbox>
  <!-- <app-complete-data
    *ngIf="_signupService.showCompleteData$.value"
  ></app-complete-data> -->
</div>
