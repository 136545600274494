/* eslint-disable no-underscore-dangle */
import { Injectable, inject } from '@angular/core';
// import { StorageAdapter } from './adapters/storage-adapter';
import { LoggerAdapter } from './adapters/logger-adapter';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * handle the device internal DB
 *
 * @export
 * @class StorageService
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  /**
   * Inject the LoggerAdapter
   *
   * @private
   * @type {LoggerAdapter}
   * @memberof StorageService
   */
  private loggerService: LoggerAdapter = inject(LoggerAdapter);
  public changeStorage$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   * Creates an instance of StorageService.
   * @memberof StorageService
   */
  constructor() {
    // super();
    this.loggerService.init('StorageService()');
  }

  /**
   * store a key with its value in the storage
   *
   * @param {string} key - the key to store
   * @param {*} value - the value of the key
   * @return {*}  {Promise<void>}
   * @memberof StorageService
   */
  public set(key: string, value: any): void {
    try {
      sessionStorage.setItem(key, value);
      this.changeStorage$.next({ key, value });
    } catch (e) {
      this.loggerService.error('StorageService - set() ' + JSON.stringify(e));
    }
  }

  /**
   * obtain the value of a desired jey
   *
   * @param {string} key - the key to obtain
   * @return {*}  {Promise<any>}
   * @memberof StorageService
   */
  public get(key: string): any {
    try {
      return sessionStorage.getItem(key);
    } catch (e) {
      this.loggerService.error('StorageService() - get - ' + JSON.stringify(e));
    }
  }

  /**
   * remove a key from the storage
   *
   * @param {string} key - the key to remove
   * @return {*}  {Promise<any>}
   * @memberof StorageService
   */
  public async remove(key: string): Promise<any> {
    try {
      return await sessionStorage.removeItem(key);
    } catch (e) {
      this.loggerService.error(
        'StorageService() - remove - ' + JSON.stringify(e)
      );
    }
  }

  /**
   * Remove Previous Saved data and store the new one
   *
   * @param {*} key - the key to store
   * @param {*} data - data to store
   * @return {*}  {Promise<void>}
   * @memberof StorageService
   */
  public async storeData(key, data): Promise<void> {
    this.remove(key);
    this.set(key, data);
  }

  /**
   * clear all the keys in the storage
   *
   * @return {*}  {Promise<void>}
   * @memberof StorageService
   */
  public async clear(): Promise<void> {
    try {
      await sessionStorage.clear();
    } catch (e) {
      this.loggerService.error('StorageService() - get - ' + JSON.stringify(e));
    }
  }
}
