import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/core/providers/infrastructure/storage.service';

@Component({
  selector: 'app-calendar-inline-mobile',
  standalone: true,
  imports: [CommonModule, CalendarModule, FormsModule, TranslateModule],
  templateUrl: './calendar-inline-mobile.component.html',
  styleUrls: ['./calendar-inline-mobile.component.scss'],
})
export class CalendarInlineMobileComponent implements OnInit {
  rangeDates: any;
  minDate: Date = new Date();
  @Output() selectionDates: EventEmitter<any> = new EventEmitter();
  public storage = inject(StorageService);

  ngOnInit(): void {
    this.storage.changeStorage$.subscribe(res => {
      if (res) {
        if (res.key == environment.storeKeys.DESTINATION) {
          let destination = JSON.parse(res.value);
          let ranges = [];
          destination.rangeDates.forEach(date => {
            ranges.push(new Date(date));
          });
          this.rangeDates = ranges;
        }
      }
    });
  }
  onSelectDates(event): void {
    if (this.rangeDates[0] && this.rangeDates[1]) {
      this.selectionDates.emit(this.rangeDates);
    }
  }

  onInput(event): void {
    let e = event;
  }
}
