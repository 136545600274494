<div class="popup-location">
  <span class="p-input-icon-right">
    <i class="fa-solid fa-location-dot color-link"></i>
    <input
      [(ngModel)]="destiny"
      type="text"
      pInputText
      placeholder="{{ 'SHARED.DESTINATION.destination' | translate }}"
      (keyup)="filterDestination($event)"
      (focus)="focusSelectionDestiny($event)" />
  </span>
</div>

<div *ngIf="destinyIsSelected" class="mt-2 dates-destination">
  <p-calendar
    id="dateCalendar"
    #dateCalendar
    class="dates-calendar"
    appendTo="body"
    [(ngModel)]="rangeDates"
    selectionMode="range"
    [readonlyInput]="true"
    inputId="range"
    [showIcon]="true"
    placeholder="{{ 'SHARED.DESTINATION.calendar' | translate }}"
    icon="fa-regular fa-calendar color-link"
    (onFocus)="focusSelectionDates()">
  </p-calendar>
</div>

<div *ngIf="destinyIsSelected && datesIsSelected" class="mt-2 travellers">
  <span class="p-input-icon-right">
    <i class="fa-solid fa-users color-link"></i>
    <input
      [(ngModel)]="travellers"
      type="text"
      pInputText
      readonly
      (focus)="focusSelectionTravellers()"
      placeholder="{{ 'SHARED.DESTINATION.travellers' | translate }}" />
  </span>
</div>

<div
  class="popup-location-card"
  [ngClass]="{
    'height-80': !destinyIsSelected,
    'height-70': destinyIsSelected && !datesIsSelected,
    'height-60': destinyIsSelected && datesIsSelected
  }">
  <p-card>
    <app-filter-destinations
      *ngIf="!destinyIsSelected"
      [showResults]="showResults"
      (selectionDestiny)="
        destinySelectedEvent($event)
      "></app-filter-destinations>
    <app-last-search-destination
      *ngIf="!showResults"></app-last-search-destination>
    <app-calendar-inline-mobile
      *ngIf="destinyIsSelected && !datesIsSelected"
      (selectionDates)="
        datesSelectedEvent($event)
      "></app-calendar-inline-mobile>
    <app-travellers-inline-mobile
      *ngIf="destinyIsSelected && datesIsSelected"
      (selectionTravellers)="
        travellersSelectedEvent($event)
      "></app-travellers-inline-mobile>
    <!-- BUTTONS -->
    <div class="m-3 buttons">
      <a
        class="color-black mt-4 my-0 text-uppercase mr-4 text-xs"
        (click)="cancel()">
        {{ 'GENERAL.btn_cancel' | translate }}
      </a>
      <!-- <a
        *ngIf="!destinyIsSelected || !datesIsSelected || !travellersIsSelected"
        class="color-link mt-4 my-0 text-uppercase text-xs"
        (click)="goToNext()">
        {{ 'GENERAL.btn_next' | translate }}
      </a> -->
      <button
        *ngIf="destinyIsSelected && datesIsSelected && travellersIsSelected"
        pButton
        pRipple
        type="button"
        label="{{ 'GENERAL.btn_search' | translate }}"
        class="p-button-rounded p-button-warning secondary color-black flex justify-content-center"
        (click)="goToSearch()"></button>
    </div>
  </p-card>
</div>
