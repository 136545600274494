import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupFormComponent } from './signup-form/signup-form.component';
import { SignupService } from 'src/app/core/providers/public/signup/signup.service';
import { GeneralService } from 'src/app/core/providers/general.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { CompleteDataComponent } from './complete-data/complete-data.component';
import { CheckYourInboxComponent } from './check-your-inbox/check-your-inbox.component';
import { StorageService } from 'src/app/core/providers/infrastructure/storage.service';

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [
    CommonModule,
    SignupFormComponent,
    CompleteDataComponent,
    CheckYourInboxComponent,
    ToastModule,
  ],
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers: [],
})
export class SignUpComponent implements OnInit, OnDestroy {
  initSignup: boolean = false;
  private storage = inject(StorageService);

  constructor(
    public _signupService: SignupService,
    private _generalService: GeneralService,
    private _messageService: MessageService
  ) {}

  async ngOnInit(): Promise<any> {
    this.initSignup = await this.storage.get(environment.storeKeys.UUID);
    if (!this.initSignup) {
      this._signupService.showCheckYourInbox$.next(false);
    } else {
      this._signupService.showCheckYourInbox$.next(true);
    }
  }

  ngOnDestroy(): void {
    this._signupService.showCheckYourInbox$.next(false);
    this._signupService.showCompleteData$.next(false);
    this._generalService.clickMoreInfo$.next(false);
  }

  showMessage(res) {
    this._messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: res.message,
    });
  }
}
