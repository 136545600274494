<p-toast></p-toast>

<p-dialog
  class="spinner"
  [(visible)]="visibleLoading"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="false"
  [showHeader]="false"
  (onHide)="closeDialog()">
  <p-progressSpinner></p-progressSpinner>
</p-dialog>
<p-dialog
  class="login"
  [(visible)]="visibleLogin"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [showHeader]="false"
  (onHide)="closeDialog()">
  <app-login></app-login>
</p-dialog>
<p-dialog
  [(visible)]="visibleSignup"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [showHeader]="false"
  [ngClass]="{
    signup:
      !_signupService.showCheckYourInbox$.value &&
      !_signupService.showCompleteData$.value,
    inbox: _signupService.showCheckYourInbox$.value,
    'complete-data': _signupService.showCompleteData$.value
  }">
  <app-sign-up></app-sign-up>
</p-dialog>
<p-dialog
  class="view-price"
  [(visible)]="visiblePopupViewPrice"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [showHeader]="false">
  <app-popup-view-price></app-popup-view-price>
</p-dialog>
<p-dialog
  class="new-payment"
  [(visible)]="visibleUpgrade"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [showHeader]="false">
  <app-new-payment-modal
    (upgradeEmitter)="newAccount()"></app-new-payment-modal>
</p-dialog>
<p-dialog
  class="payment-subscription"
  [(visible)]="visiblePaymentSubscription"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [showHeader]="false">
  <app-payment-subscription-modal></app-payment-subscription-modal>
</p-dialog>
<p-dialog
  class="upgraded"
  [(visible)]="visibleUpgraded"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [showHeader]="false">
  <app-popup-upgraded></app-popup-upgraded>
</p-dialog>
<p-dialog
  class="cancel-trip"
  [(visible)]="visibleCancelTrip"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [showHeader]="false">
  <app-popup-cancel-trip></app-popup-cancel-trip>
</p-dialog>
<p-dialog
  class="canceled-trip"
  [(visible)]="visibleTripCanceled"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [showHeader]="false">
  <app-popup-trip-canceled></app-popup-trip-canceled>
</p-dialog>
<p-dialog
  class="working"
  [(visible)]="visibleWorking"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [showHeader]="false">
  <app-popup-working></app-popup-working>
</p-dialog>
<p-dialog
  class="errors"
  [(visible)]="visibleErrors"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [showHeader]="false">
  <app-popup-error
    [title]="titleError$ | async"
    [message]="messageError$ | async"
    [image]="imageError$ | async"
    [btnLabel]="btnLabelError$ | async"
    [btnTxt]="btnTxtError$ | async"></app-popup-error>
</p-dialog>
<p-dialog
  class="recover"
  [(visible)]="visibleRecoverPass"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [showHeader]="false"
  (onHide)="closeDialog()">
  <app-recover-pass></app-recover-pass>
</p-dialog>

<p-dialog
  class="set-new-pass"
  [(visible)]="visibleSetNewPass"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [showHeader]="false">
  <app-set-new-pass></app-set-new-pass>
</p-dialog>

<p-dialog
  class="destination"
  [(visible)]="visibleDestination"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [showHeader]="false">
  <app-popup-destination-mobile></app-popup-destination-mobile>
</p-dialog>

<p-dialog
  class="gallery"
  [(visible)]="visibleGallery"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [showHeader]="false"
  (onHide)="closeDialog()">
  <app-gallery-hotel [medias]="medias"></app-gallery-hotel>
</p-dialog>

<router-outlet>
  <app-sidebar-login-signup
    [showSidebar]="
      _sidebarLoginSignupService.showSidebar$.value
    "></app-sidebar-login-signup>
  <app-sidebar-private
    [showSidebar]="
      _sidebarPrivateService.showSidebarPrivate$.value
    "></app-sidebar-private>
</router-outlet>
