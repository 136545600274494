<div class="last-search">
  <p class="title">
    {{ 'DASHBOARD.last_searchs.title' | translate }}
  </p>
  <div class="scroll">
    <div *ngFor="let trip of lastSerachs">
      <div class="trip-information">
        <div class="flex flex-column">
          <span class="color-light-text lb-date mb-1 mt-2">
            {{ trip.date }}
          </span>
          <span class="color-black lb-location mb-1">{{ trip.location }}</span>
          <span class="lb-guest mb-2">
            {{ trip.guestNumbers }}
          </span>
        </div>
        <div>
          <span class="btn-go">{{ 'GENERAL.btn_go' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
