import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeneralService } from '../../general.service';
import { CitiesSearch } from 'src/app/core/models/cities.model';

@Injectable({
  providedIn: 'root',
})
export class DestinationService {
  public zoneSearchs$: BehaviorSubject<CitiesSearch[]> = new BehaviorSubject<
    CitiesSearch[]
  >([]);
  public citiesSearchs$: BehaviorSubject<CitiesSearch[]> = new BehaviorSubject<
    CitiesSearch[]
  >([]);
  public destinySelected$: BehaviorSubject<CitiesSearch> =
    new BehaviorSubject<CitiesSearch>(null);
  destination = {
    data: [
      {
        city_name: 'Barcelona',
        type: 'hotel',
        label: 'NH Barcelona',
      },
      {
        city_name: 'Barcelona',
        type: 'city',
        label: 'Barcelona, Cataluña, España',
      },
      {
        city_name: 'Barcelona',
        type: 'airport',
        label: 'Aeropuerto de Barcelona',
      },
      {
        city_name: 'Madrid',
        type: 'hotel',
        label: 'AC Madrid',
      },
      {
        city_name: 'Madrid',
        type: 'city',
        label: 'Comunidad de madrid, Madrid, España',
      },
      {
        city_name: 'Madrid',
        type: 'airport',
        label: 'Aeropuerto Barajas, Madrid',
      },
    ],
  };

  constructor(private httpClient: HttpClient) {}

  getDestination(word: string): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${environment.apiUrl}/booking/search?term=${word}`
    );
  }
}
