<div class="calendar-inline">
  <p-calendar
    id="dateCalendar"
    #dateCalendar
    [inline]="true"
    class="dates-calendar"
    appendTo="body"
    [(ngModel)]="rangeDates"
    selectionMode="range"
    [readonlyInput]="true"
    inputId="range"
    [showIcon]="true"
    placeholder="{{ 'SHARED.DESTINATION.calendar' | translate }}"
    [minDate]="minDate"
    icon="fa-regular fa-calendar"
    (onSelect)="onSelectDates($event)"
    (onInput)="onInput($event)">
  </p-calendar>
</div>
