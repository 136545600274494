<div class="content flex flex-row justify-content-center align-items-center">
  <p-card class="">
    <ng-template pTemplate="header">
      <img
        src="assets/images/star_payment.png"
        alt="star-payment"
        width="99px"
        height="70.43px" />
      <h5 class="color-black mb-2 text-center">
        Don’t you want to <br />
        access to better prices?
      </h5>
    </ng-template>

    <div class="my-2 justify-content-center w-100">
      <div class="p-1 column">
        <div class="card border-round-3xl bg-quaternary px-4">
          <div
            class="heading-wrapper flex flex-column justify-content-center align-items-center">
            <div
              class="optimizer-image flex flex-column justify-content-center align-items-center mt-3 mb-3">
              <!-- <img
                ngSrc="./assets/images/nomadic.svg"
                alt="nomadic"
                priority
                width="78"
                height="78"
              /> -->
            </div>
            <h5 class="heading-text color-white text-lg">
              Get Nomad membership!
            </h5>
            <h6 class="subheading-text color-white text-base">
              You will save $220
            </h6>
          </div>

          <hr />

          <ul class="list-item color-info-dark-50">
            <li class="flex flex-row justify-content-start align-items-center">
              <div class="optimizer-image mr-3">
                <i class="fa-solid fa-check color-green-doc"></i>
              </div>
              <h6 class="color-white">
                {{ 'HOME.MEMBERSHIP.free_card.benefit_1' | translate }}
              </h6>
            </li>
            <li class="flex flex-row justify-content-start align-items-center">
              <div class="optimizer-image mr-3">
                <i class="fa-solid fa-check color-green-doc"></i>
              </div>
              <h6 class="color-white">
                {{ 'HOME.MEMBERSHIP.free_card.benefit_2' | translate }}
              </h6>
            </li>
            <li class="flex flex-row justify-content-start align-items-center">
              <div class="optimizer-image mr-3">
                <i class="fa-solid fa-check color-green-doc"></i>
              </div>
              <h6 class="color-white">
                {{ 'HOME.MEMBERSHIP.free_card.benefit_3' | translate }}
              </h6>
            </li>
            <li class="flex flex-row justify-content-start align-items-center">
              <div class="optimizer-image mr-3">
                <i class="fa-solid fa-check color-green-doc"></i>
              </div>
              <h6 class="color-white">
                {{ 'HOME.MEMBERSHIP.free_card.benefit_4' | translate }}
              </h6>
            </li>
          </ul>

          <div
            *ngIf="isPrivateView"
            class="flex flex-row justify-content-center align-items-center section"
            [ngClass]="{
              'mt-4 mb-5': !isPrivateView,
              'mt-2 mb-2': isPrivateView
            }">
            <span
              ><i class="fa-solid fa-circle-check color-valid"></i>
              {{ 'PROFILE.my_membership' | translate }}</span
            >
          </div>

          <div
            class="flex flex-row justify-content-center align-items-center sign-up-option py-4"
            *ngIf="!isPrivateView">
            <button
              pButton
              pRipple
              (click)="upgradeAccount()"
              type="button"
              label="Upgrade for $199/year"
              class="p-button-rounded p-button bg-white color-quaternary"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex align-items-center justify-content-center">
      <a class="mt-2 color-dark text-uppercase" (click)="closePopup()"
        >no, i prefer to be sedentary</a
      >
    </div>
  </p-card>
</div>
