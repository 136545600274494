import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef,
  AfterViewChecked,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PasswordModule } from 'primeng/password';
import { AvatarProfileComponent } from '../avatar-profile/avatar-profile.component';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { StripeAPIService } from 'src/app/core/providers/private/stripe/stripe-api.service';
import { ExpCardFormatterDirective } from 'src/app/core/directives/exp-card.directive';
import { InputMaskModule } from 'primeng/inputmask';
import { CreditCard } from 'src/app/core/models/credit-card.model';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';

interface ICreditCardOptions {
  name: string;
  key: string;
}

@Component({
  selector: 'app-payment-methods',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    InputTextModule,
    InputNumberModule,
    CalendarModule,
    InputSwitchModule,
    RadioButtonModule,
    AvatarProfileComponent,
    PasswordModule,
    RadioButtonModule,
    FormsModule,
    InputMaskModule,
    ExpCardFormatterDirective,
    ToastModule,
  ],
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
  providers: [],
})
export class PaymentMethodsComponent {
  cardSelected: any = null;
  private stripe: Stripe;
  elementStripe: any;
  selectedCard: any;

  @Input() onSelectPaymentMethod: boolean = false;
  @Input() ignoreButtonProced: boolean = false;
  creditCardList$: Observable<CreditCard[]>;
  @Output() proceedToPay = new EventEmitter<any>();
  @Output() creditCardSelected = new EventEmitter<any>();

  public isAdding: boolean = false;
  public creditCardForm: FormGroup = new FormGroup({});
  public creditSelectedForm: FormGroup = new FormGroup({});
  public creditCardOptions: ICreditCardOptions[] = [
    { name: 'Visa', key: 'V' },
    { name: 'Mastercard', key: 'MC' },
    { name: 'American Express', key: 'AM' },
    { name: 'Other', key: 'O' },
  ];

  public saveOptions: ICreditCardOptions[] = [
    { name: 'Save credit card for next pruchases', key: 'save' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private _stripeService: StripeAPIService,
    private _messageService: MessageService,
    public _deviceDetectorService: DeviceDetectorService
  ) {
    this.creditCardList$ = this._stripeService.creditCardList$;
  }

  async ngOnInit(): Promise<any> {
    await loadStripe(environment.stripe_pk).then(result => {
      this.stripe = result;
    });
    this.createForm();
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   // if (changes['sourceList']) {
  //   //   this.creditCardList = changes['sourceList'].currentValue;
  //   // }
  // }

  async chooseDefaultPaymentCard(card_id: string) {
    try {
      await this._stripeService.choosePaymentDefault(card_id).then(_ => {
        this._stripeService
          .getListCard()
          .then(res => {
            this._messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Set default card',
            });
          })
          .catch(e => {
            this._messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'No set default card',
            });
          });
      });
    } catch (error) {
      console.log(error);
    }
  }

  public addNewCreditCard(): void {
    this.isAdding = true;
    this.cdr.detectChanges();
    this.elementStripe = this.stripe
      .elements()
      .create('card', { style: {}, disableLink: true, hidePostalCode: true });
    this.elementStripe.mount('#elementCard');
  }

  async confirmCreditCard() {
    const { token, error } = await this.stripe.createToken(this.elementStripe);
    if (token) {
      this._stripeService
        .sendTokenCard(token.id)
        .then(res => {
          if (res.status === 201) {
            this._messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Card added',
            });

            this._stripeService
              .getListCard()
              .then(_ => {
                this.isAdding = false;
              })
              .catch(e => {
                this._messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'It is not possible retrieve cards',
                });
              });
          }
        })
        .catch(e => {
          this._messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Card no added',
          });
        });
    } else {
      console.log('ERROR: ' + error.message);
    }
  }

  cancelCreditCard() {
    this.isAdding = false;
  }

  createForm(): void {
    this.creditCardForm = this.formBuilder.group({
      cardholdName: [''],
      cardNumber: [''],
      expirationDate: [''],
      cvs: [''],
      cardType: ['V'],
      saveCard: ['V'],
    });
  }

  public onProceedToPayment(): void {
    this.proceedToPay.emit(this.cardSelected);
  }

  setCard(card: any): void {
    this.creditCardSelected.emit(card.value);
    this.cardSelected = card.value;
  }

  deleteCard(card_id: string): void {
    this._stripeService
      .deleteCard(card_id)
      .then(res => {
        this._messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Card delete',
        });
        this._stripeService.getListCard().catch(e => {
          this._messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'It is not possible retrieve cards',
          });
        });
      })
      .catch(e => {});
  }
}
