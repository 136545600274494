import { Component, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { QuicklinkDirective, QuicklinkModule } from 'ngx-quicklink';
import { RouterLink } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SignupService } from 'src/app/core/providers/public/signup/signup.service';
import { ReactiveFormsModule } from '@angular/forms';
import { SidebarLoginSignupService } from 'src/app/core/providers/public/sidebar-login-signup/sidebar-login-signup.service';
import { GeneralService } from 'src/app/core/providers/general.service';
import { AuthService } from 'src/app/core/providers/private/auth/auth.service';

@Component({
  selector: 'app-content-signup-form',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    TranslateModule,
    QuicklinkDirective,
    QuicklinkModule,
    RouterLink,
    ReactiveFormsModule,
  ],
  templateUrl: './content-signup-form.component.html',
  styleUrls: ['./content-signup-form.component.scss'],
})
export class ContentSignupFormComponent {
  signupForm: FormGroup = new FormGroup({});
  passConfirm: boolean = false;
  error: string;
  @Output() toastEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private _signupService: SignupService,
    public _sidebarLoginSignupService: SidebarLoginSignupService,
    public _generalService: GeneralService,
    public _authService: AuthService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.signupForm.controls;
  }

  createForm(): void {
    this.signupForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      pass: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.* )(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}'
          ),
        ],
      ],
      confirmPass: ['', [Validators.required]],
    });
    this.signupForm.setValidators([this.passConfirmationValidator]);
  }

  passConfirmationValidator = (form: FormGroup) => {
    const pass = form.get('pass').value;
    const confirmPass = form.get('confirmPass').value;

    return pass === confirmPass
      ? null
      : { passConfirm: 'pass confirm mismatch' };
  };

  onChange(event) {
    if (
      this.signupForm.controls['pass'].value ==
      this.signupForm.controls['confirmPass'].value
    ) {
      this.passConfirm = true;
    } else {
      this.passConfirm = false;
    }
  }

  onSignup(): void {
    if (this.signupForm.status == 'VALID') {
      this._authService
        .signUp(
          this.signupForm.controls['email'].value,
          this.signupForm.controls['pass'].value
        )
        .then((res) => {
          this.error = null;
          this._signupService.saveEmail$.next(
            this.signupForm.controls['email'].value
          );
          // this.toastEmitter.emit(res);
          // alert('Te hemos enviado un email para validar tu cuenta');
          // return;
          this._generalService.showSignup$.next(true);
          this._signupService.showCheckYourInbox$.next(true);
        })
        .catch((e) => {
          this.error = e.error.message;
        });
    }
  }
}
