<div class="popup-view-price">
  <p-card class="color-quaternary" header="{{ title$ | async }}">
    <ng-template pTemplate="header">
      <div class="">
        <img
          alt="bird"
          src="assets/images/upgraded.svg"
          width="180"
          height="181"
        />
      </div>
    </ng-template>
    <div
      class="col-12 flex flex-column justify-content-start align-items-center buttons"
    >
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'TRIP.flow.got_it' | translate }}"
        class="p-button-rounded p-button-warning secondary color-black mb-2"
        (click)="goIt()"
      ></button>
    </div>
  </p-card>
</div>
