import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Preference_Category } from 'src/app/core/models/preference-category.model';
import { Voyager_Session } from 'src/app/core/models/voyager-session.model';
import { Voyagers_Info } from 'src/app/core/models/voyagers-info.model';
import { environment } from 'src/environments/environment';
import { GeneralService } from '../../general.service';
import * as CryptoJS from 'crypto-js';
import { AESEncryptDecryptService } from '../../encrypt/crypto.service';
import { Voyager_Activities_Searched } from 'src/app/core/models/voyager-activities-searched.model';

@Injectable({
  providedIn: 'root',
})
export class VoyagersService {
  private locationSubject = new BehaviorSubject([]);

  public locations$: Observable<any[]> = this.locationSubject.asObservable();
  showFilters$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  rangeHistoricSaveSlider$: BehaviorSubject<number[]> = new BehaviorSubject<
    number[]
  >([]);
  minHistoricSaveSlider$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );
  maxHistoricSaveSlider$: BehaviorSubject<number> = new BehaviorSubject<number>(
    3000
  );
  rangeHistoricPriceSlider$: BehaviorSubject<number[]> = new BehaviorSubject<
    number[]
  >([]);
  minHistoricPriceSlider$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  maxHistoricPriceSlider$: BehaviorSubject<number> =
    new BehaviorSubject<number>(3000);
  filteredTrips$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  voyagerEmailSession$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  stepperOnBoarding$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  stepperInterests$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  private initData: Array<any> = [];
  interestList$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  preferenceList$: BehaviorSubject<Preference_Category[]> = new BehaviorSubject(
    []
  );
  private habbitSubject = new BehaviorSubject(this.initData);

  public voyagerSession$: BehaviorSubject<Voyager_Session> =
    new BehaviorSubject<Voyager_Session>({
      firstName: '',
      name: '',
      email: '',
      avatar: '',
      membership: '',
      shortName: '',
    });
  public visibleDelete$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public favoriteList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    private httpClient: HttpClient,
    private _generalService: GeneralService,
    private cryptoService: AESEncryptDecryptService
  ) {}

  getProfileVoyager(): Promise<Voyagers_Info> {
    let uuid = sessionStorage.getItem('uuid');
    this._generalService.showLoading$.next(false);
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(`${environment.apiUrl}/voyager/voyager-profile/${uuid}`)
        .subscribe({
          next: (res: any) => {
            resolve(res.data);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  getCountryList(): Promise<any> {
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(`${environment.apiUrl}/destination/get-locations`)
        .subscribe({
          next: (res: any) => {
            this.locationSubject.next(res.data);
            resolve(res.data);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  getSexList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(`${environment.apiUrl}/voyager/get-voyager-sex`)
        .subscribe({
          next: (res: any) => {
            resolve(res.data);
          },
          error: e => {
            reject(e);
            console.error(e);
          },
          complete: () => {},
        });
    });
  }

  updateProfileVoyager(voyagerInfo: Voyagers_Info): Promise<any> {
    let uuid = sessionStorage.getItem('uuid');
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .patch(
          `${environment.apiUrl}/voyager/update-voyager-profile/${uuid}/`,
          voyagerInfo
        )
        .subscribe({
          next: res => {
            resolve(res);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  uploadFileVoyager(voyagerPhoto: FormData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/voyager/upload-file`, voyagerPhoto)
        .subscribe({
          next: res => {
            resolve(res);
          },
          error: e => {
            reject(e);
            console.error(e);
          },
          complete: () => {},
        });
    });
  }

  public getVoyagerInfoSession(): Promise<any> {
    const uuid = sessionStorage.getItem('uuid');
    if (uuid != null) {
      this._generalService.showLoading$.next(true);
      return new Promise((resolve, reject) => {
        this.httpClient
          .get(`${environment.apiUrl}/voyager/get-session/${uuid}`)
          .subscribe({
            next: ({ data }: any) => {
              this.voyagerSession$.next({
                firstName: data.full_name.split(' ')[0],
                name: data.full_name,
                email: data.email,
                avatar: data?.avatar,
                membership: data.plan,
                shortName: data.short_name,
              });
              this._generalService.showLoading$.next(false);
              resolve(this.voyagerSession$.value);
            },
            error: e => {
              this._generalService.showLoading$.next(false);
              reject(e);
            },
            complete: () => {
              this._generalService.showLoading$.next(false);
            },
          });
      });
    } else {
      return null;
    }
  }

  public getInterestList(): Promise<any> {
    const uuid = sessionStorage.getItem('uuid');
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(`${environment.apiUrl}/voyager/category-interest-list/${uuid}`)
        .subscribe({
          next: (res: any) => {
            this.interestList$.next(res?.data);
            resolve(res?.data);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  public getPreferenceList(): Promise<any> {
    const uuid = sessionStorage.getItem('uuid');
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(`${environment.apiUrl}/voyager/category-preferences-list/${uuid}`)
        .subscribe({
          next: (res: any) => {
            this.preferenceList$.next(res?.data);
            resolve(res.data);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  public getFavoriteAccomodationList(): Promise<any> {
    const uuid = sessionStorage.getItem('uuid');
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(`${environment.apiUrl}/voyager/favorite-accomodation/${uuid}`)
        .subscribe({
          next: (res: any) => {
            this.preferenceList$.next(res?.data);
            this.favoriteList$.next(res?.data);
            resolve(res.data);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  public setFavoriteAccomodation(accomodationId: number): Promise<any> {
    const uuid = sessionStorage.getItem('uuid');
    if (uuid == null) {
      this._generalService.showLogin$.next(true);
      return null;
    } else {
      let obj = { uuid, accomodation_id: accomodationId };
      return new Promise((resolve, reject) => {
        this.httpClient
          .post(`${environment.apiUrl}/voyager/favorite-accomodation`, obj)
          .subscribe({
            next: res => {
              resolve(res);
            },
            error: e => {
              reject(e);
              console.error(e);
            },
            complete: () => {},
          });
      });
    }
  }

  public deleteFavoriteAccomodation(accomodationId: number): Promise<any> {
    const uuid = sessionStorage.getItem('uuid');
    let obj = { uuid, accomodation_id: accomodationId };
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(`${environment.apiUrl}/voyager/favorite-accomodation`, {
          params: obj,
        })
        .subscribe({
          next: res => {
            resolve(res);
          },
          error: e => {
            reject(e);
            console.error(e);
          },
          complete: () => {},
        });
    });
  }

  public getHabbitList(): Promise<any> {
    const uuid = sessionStorage.getItem('uuid');
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(`${environment.apiUrl}/voyager/category-habbit-list/${uuid}`)
        .subscribe({
          next: (res: any) => {
            this.habbitSubject.next(res?.data);
            resolve(res.data);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  public saveHabbitList(habbitList: any): Promise<any> {
    const uuid = sessionStorage.getItem('uuid');
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/voyager/save-habbits-category`, {
          uuid,
          habbit_selected: [...habbitList],
        })
        .subscribe({
          next: (res: any) => {
            resolve(res);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  public saveInterestList(interestList: any): Promise<any> {
    const uuid = sessionStorage.getItem('uuid');
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/voyager/save-interest-category`, {
          uuid,
          interest_selected: interestList,
        })
        .subscribe({
          next: (res: any) => {
            resolve(res);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  public savePreferencesList(preferenceList: any): Promise<any> {
    const uuid = sessionStorage.getItem('uuid');
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/voyager/save-preferences-category`, {
          uuid,
          preferences_selected: [...preferenceList],
        })
        .subscribe({
          next: (res: any) => {
            resolve(res);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  deleteAccount(password: string) {
    const passwordEncrypt = this.cryptoService.encrypt(password);

    let session = JSON.parse(sessionStorage.getItem('session'));
    let model = { email: session.email, password: passwordEncrypt };

    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(`${environment.apiUrl}/voyager/delete-account`, {
          params: model,
        })
        .subscribe({
          next: (res: any) => {
            this._generalService.showLoading$.next(false);
            resolve(res);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  public getMyActivitiesSearched(): Promise<Voyager_Activities_Searched> {
    const uuid = sessionStorage.getItem('uuid');
    if (uuid != null) {
      this._generalService.showLoading$.next(true);
      return new Promise((resolve, reject) => {
        this.httpClient
          .get(`${environment.apiUrl}/voyager/activity-searchs/${uuid}`)
          .subscribe({
            next: (res: any) => {
              this._generalService.showLoading$.next(false);
              resolve(res.data);
            },
            error: e => {
              this._generalService.showLoading$.next(false);
              reject(e);
            },
            complete: () => {
              this._generalService.showLoading$.next(false);
            },
          });
      });
    } else {
      return null;
    }
  }
}
