import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { retry, tap } from 'rxjs';
import { AuthService } from '../providers/private/auth/auth.service';
import { inject } from '@angular/core';
import { GeneralService } from '../providers/general.service';

export const retryInterceptor = (times) => {
  const authInterceptor = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
  ) => {
    let accessToken: string = '';
    const authService = inject(AuthService);
    const generalService = inject(GeneralService);

    if (!sessionStorage.getItem('access')) {
      authService.token$.subscribe((token: string) => {
        accessToken = token;
      });
    } else {
      accessToken = sessionStorage.getItem('access');
    }

    const clonedRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return next(clonedRequest).pipe(
      tap({
        next: () => retry(times),
        error: (e) => {
          if (e.status == 401 || e.status === 403) {
            generalService.onLogout();
          }
        },
      })
    );
  };

  return authInterceptor;
};
