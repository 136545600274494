import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RecoverService } from 'src/app/core/providers/public/recover/recover.service';
import { AuthService } from 'src/app/core/providers/private/auth/auth.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-recover-form',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    CardModule,
    TranslateModule,
    ButtonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './recover-form.component.html',
  styleUrls: ['./recover-form.component.scss'],
})
export class RecoverFormComponent implements OnInit, OnDestroy {
  recoverForm: FormGroup;
  subscription: Subscription;
  @Output() toastEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private _authService: AuthService,
    private _recoverService: RecoverService,
    private _messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.recoverForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.subscription = this._recoverService.showNewPass$.subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onContinue() {
    const email = this.recoverForm.controls['email'].value;

    this._authService
      .forgotPassword(email)
      .then(res => {
        if (res.status < 300) {
          this._recoverService.showNewPass$.next(true);
        }
        this.toastEmitter.emit(res);
      })
      .catch(e => {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: e.error.message,
        });
      });
  }
}
