import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CardModule } from 'primeng/card';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { GeneralService } from 'src/app/core/providers/general.service';
import { FlowService } from 'src/app/core/providers/trip/flow.service';

@Component({
  selector: 'app-popup-cancel-trip',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    TranslateModule,
    NgOptimizedImage,
    ButtonModule,
  ],
  templateUrl: './popup-cancel-trip.component.html',
  styleUrls: ['./popup-cancel-trip.component.scss'],
})
export class PopupCancelTripComponent {
  constructor(
    private _generalService: GeneralService,
    private _flowService: FlowService
  ) {}

  confirmCancelTrip(): void {
    this._generalService.showPopupCancelTrip$.next(false);
    this._generalService.showPopupTripCanceled$.next(true);
    this._flowService.cancelReservation(
      this._flowService.reservationConfirm$.value.reservation.bookindId
    );
  }

  unconfirmCancelTrip(): void {
    this._generalService.showPopupCancelTrip$.next(false);
  }
}
