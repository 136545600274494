<form [formGroup]="signupForm">
  <div class="field">
    <span class="p-input-icon-right">
      <div class="field">
        <label class="input-label">{{
          "SIGNUP.SIGNUP_FORM.email" | translate
        }}</label>
        <input formControlName="email" type="text" pInputText required />
      </div>
    </span>
    <div
      *ngIf="f['email'].errors && f['email'].touched"
      class="invalid-feedback"
    >
      <div *ngIf="f['email'].errors['required']">
        {{ "GENERAL.ERRORS.email_required" | translate }}
      </div>
      <div *ngIf="f['email'].errors['email']">
        {{ "GENERAL.ERRORS.email_error" | translate }}
      </div>
    </div>
  </div>

  <div class="field">
    <span class="p-input-icon-right">
      <div class="field">
        <label class="input-label">{{
          "SIGNUP.SIGNUP_FORM.pass" | translate
        }}</label>
        <input
          formControlName="pass"
          type="password"
          pInputText
          required
          (keyup)="onChange($event)"
        />
      </div>
    </span>
    <div *ngIf="f['pass'].errors && f['pass'].touched" class="invalid-feedback">
      <div *ngIf="f['pass'].errors['required']">
        {{ "GENERAL.ERRORS.pass_required" | translate }}
      </div>
      <div *ngIf="f['pass'].errors['pattern']">
        {{ "GENERAL.ERRORS.pattern_password" | translate }}
      </div>
    </div>
  </div>

  <div class="field">
    <span class="p-input-icon-right">
      <div class="field">
        <label class="input-label">{{
          "SIGNUP.SIGNUP_FORM.confirmPass" | translate
        }}</label>
        <input
          formControlName="confirmPass"
          type="password"
          pInputText
          required
          (keyup)="onChange($event)"
        />
      </div>
    </span>
    <div
      *ngIf="f['confirmPass'].errors && f['confirmPass'].touched"
      class="invalid-feedback"
    >
      <div *ngIf="f['confirmPass'].errors['required']">
        {{ "GENERAL.ERRORS.confirm_pass_required" | translate }}
      </div>
    </div>
    <div *ngIf="!passConfirm && signupForm.errors" class="invalid-feedback">
      <div *ngIf="signupForm.errors['passConfirm']">
        {{ "GENERAL.ERRORS.distinct_pass" | translate }}
      </div>
    </div>
  </div>
</form>
<div *ngIf="error" class="invalid-feedback mb-4">
  {{ error }}
</div>
<div class="field btn-signup">
  <button
    [disabled]="signupForm.invalid"
    pButton
    pRipple
    type="button"
    label="{{ 'GENERAL.btn_sign_up' | translate }}"
    class="p-button-rounded p-button-warning bg-secondary flex justify-content-center w-full"
    (click)="onSignup()"
  ></button>
</div>

<!-- <div class="text-center signup-with">
  <p
    *ngIf="!_sidebarLoginSignupService.isSidebar$.value"
    class="text-center mt-5 mb-3 color-light-dark"
  >
    {{ "SIGNUP.SIGNUP_FORM.have_account" | translate }}
    <a class="color-light-dark underline" (click)="_generalService.onLogin()">{{
      "GENERAL.btn_login" | translate
    }}</a>
    {{ "SIGNUP.SIGNUP_FORM.signup_with" | translate }}
  </p>
  <p
    class="font-family-light font-bold color-light-text mb-3"
    *ngIf="_sidebarLoginSignupService.isSidebar$.value"
  >
    {{ "SIGNUP.SIGNUP_FORM.signup_with" | translate }}
  </p>
</div> -->

<!-- <div class="flex flex-row justify-content-center align-items-baseline">
  <img
    src="assets/images/facebook.svg"
    alt="facebook"
    width="30.83px"
    height="30.76px"
    class="m-1"
  />
  <img
    src="assets/images/google.svg"
    alt="facebook"
    width="29px"
    height="29px"
    class="m-1"
  />
</div> -->
