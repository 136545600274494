import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CardModule } from 'primeng/card';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/core/providers/general.service';

@Component({
  selector: 'app-popup-trip-canceled',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    TranslateModule,
    NgOptimizedImage,
    ButtonModule
  ],
  templateUrl: './popup-trip-canceled.component.html',
  styleUrls: ['./popup-trip-canceled.component.scss']
})
export class PopupTripCanceledComponent {

  constructor(
    private router: Router,
    private _generalService: GeneralService
  ) {

  }

  goToNewTrip():void{
    this._generalService.showPopupTripCanceled$.next(false);
    this.router.navigate(['/trip']);

  }

  goToDashboard(): void {
    this._generalService.showPopupTripCanceled$.next(false);
    this.router.navigate(['/dashboard']);
  }
}
