import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarPrivateService } from 'src/app/core/providers/private/sidebar-private/sidebar-private.service';

@Component({
  selector: 'app-content-sidebar-profile',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule],
  templateUrl: './content-sidebar-profile.component.html',
  styleUrls: ['./content-sidebar-profile.component.scss']
})
export class ContentSidebarProfileComponent {

  constructor(
    public _sidebarPrivateService: SidebarPrivateService,
  ) {

  }

}
