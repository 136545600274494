import { Routes } from '@angular/router';
import { isAutenticateGuard } from './core/providers/private/auth.guard';

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () =>
      import('./components/public/home/home.component').then(
        (m) => m.HomeComponent
      ),
  },
  {
    path: 'what-is-travelaya',
    loadComponent: () =>
      import(
        './components/public/what-is-travelaya/what-is-travelaya.component'
      ).then((m) => m.WhatIsTravelayaComponent),
  },
  {
    path: 'how-it-works',
    loadComponent: () =>
      import('./components/public/how-it-works/how-it-works.component').then(
        (m) => m.HowItWorksComponent
      ),
  },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./components/private/dashboard/dashboard.component').then(
        (m) => m.DashboardComponent
      ),
    canActivate: [isAutenticateGuard],
  },
  {
    path: 'onboarding',
    loadComponent: () =>
      import('./components/private/onboarding/on-boarding.component').then(
        (m) => m.OnBoardingComponent
      ),
    canActivate: [isAutenticateGuard],
  },
  {
    path: 'recoverpass',
    loadChildren: () =>
      import(
        './components/shared/shared-general/recover-pass/recover-pass.module'
      ).then((m) => m.RecoverPassModule),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./components/shared/shared-general/login/login.component').then(
        (m) => m.LoginComponent
      ),
  },
  {
    path: 'help',
    loadComponent: () =>
      import('./components/public/help/help.component').then(
        (m) => m.HelpComponent
      ),
  },
  {
    path: 'cookies',
    loadComponent: () =>
      import('./components/public/cookies-page/cookies-page.component').then(
        (m) => m.CookiesPageComponent
      ),
  },
  {
    path: 'signup',
    loadComponent: () =>
      import(
        './components/shared/shared-general/sign-up/sign-up.component'
      ).then((m) => m.SignUpComponent),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./components/private/profile/profile-routing.module').then(
        (m) => m.ProfileRoutingModule
      ),
    canActivate: [isAutenticateGuard],
  },
  {
    path: 'trip',
    loadComponent: () =>
      import('./components/trip/flow/flow.component').then(
        (m) => m.FlowComponent
      ),
  },
  {
    path: 'accomodation',
    loadComponent: () =>
      import('./components/trip/flow/accomodation/accomodation.component').then(
        (m) => m.AccomodationComponent
      ),
    canActivate: [isAutenticateGuard],
  },
  {
    path: 'doc',
    loadComponent: () =>
      import(
        './components/trip/flow/accomodation/edit-trip/edit-trip.component'
      ).then((m) => m.EditTripComponent),
    canActivate: [isAutenticateGuard],
  },
  {
    path: 'accomodation',
    loadComponent: () =>
      import('./components/trip/flow/accomodation/accomodation.component').then(
        (m) => m.AccomodationComponent
      ),
    canActivate: [isAutenticateGuard],
  },
  {
    path: '',
    loadComponent: () =>
      import('./components/public/home/home.component').then(
        (m) => m.HomeComponent
      ),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./components/public/home/home.component').then(
        (m) => m.HomeComponent
      ),
  },
];
