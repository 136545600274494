<p-sidebar
  [(visible)]="showSidebar"
  (onHide)="onHide()"
  (onShow)="onShow()"
  position="right"
  styleClass="w-23rem"
>
  <ng-template pTemplate="header">
    <div class="text-center w-full">
      <h5 *ngIf="_sidebarLoginSignupService.isLogin$.value">
        {{ "GENERAL.login" | translate }}
      </h5>
      <h5 *ngIf="!_sidebarLoginSignupService.isLogin$.value">
        {{ "GENERAL.btn_sign_up" | translate }}
      </h5>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <app-content-login
      *ngIf="_sidebarLoginSignupService.isLogin$.value"
    ></app-content-login>
    <app-content-signup-form
      *ngIf="!_sidebarLoginSignupService.isLogin$.value"
    ></app-content-signup-form>
  </ng-template>
  <ng-template pTemplate="footer">
    <div>
      <button
        pButton
        pRipple
        type="button"
        [ngClass]="{
          'primary color-white': _sidebarLoginSignupService.isLogin$.value,
          'secondary color-black': !_sidebarLoginSignupService.isLogin$.value
        }"
        class="p-button-rounded p-button-warning flex justify-content-center w-full"
        (click)="toggle()"
      >
        <label *ngIf="_sidebarLoginSignupService.isLogin$.value">{{
          "GENERAL.btn_sign_up" | translate
        }}</label>
        <label *ngIf="!_sidebarLoginSignupService.isLogin$.value">{{
          "GENERAL.btn_login" | translate
        }}</label>
      </button>
    </div>
  </ng-template>
</p-sidebar>
