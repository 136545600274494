import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
class AuthGuard {
  constructor(private router: Router) {}
  canActivated(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (sessionStorage.getItem('session')) {
      if (
        !JSON.parse(sessionStorage.getItem('onboarding')) &&
        route.url[0].path.includes('onboarding')
      ) {
        this.router.navigate(['/dashboard']); // TODO: CHANGE TO DASHBOARD
      }
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }
}

export const isAutenticateGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(AuthGuard).canActivated(route, state);
};
