<div class="flex flex-row justify-content-center align-items-center">
  <p-card
    class="title-signup w-full"
    header="{{ 'SIGNUP.SIGNUP_FORM.title' | translate }}"
  >
    <ng-template pTemplate="header">
      <img
        alt="signup"
        src="assets/images/img_signup.svg"
        width="85.4px"
        height="55.2px"
      />
    </ng-template>

    <app-content-signup-form></app-content-signup-form>
  </p-card>
</div>
