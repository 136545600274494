<div class="popup-error">
  <p-card class="" header="">
    <ng-template pTemplate="header">
      <div class="optimizer-image" *ngIf="image">
        <img alt="cancel" ngSrc="{{ image }}" fill />
      </div>
      <h6 class="m-0 popup-error-title">
        {{ title }}
      </h6>
      <p class="popup-error-message">
        {{ message }}
      </p>
    </ng-template>
    <div
      class="col-12 flex flex-column justify-content-start align-items-center buttons"
    >
      <button
        pButton
        pRipple
        type="button"
        label="{{ btnLabel }}"
        class="p-button-rounded p-button-warning btn-yes mb-2"
      ></button>
      <a class="btn_cancel underline">
        {{ btnTxt }}
      </a>
    </div>
  </p-card>
</div>
