<a
  [routerLink]="
    !_sidebarPrivateService.isSidebarPrivate$.value
      ? ['my-trips']
      : ['profile/my-trips']
  "
  [routerLinkActive]="'is-active'"
  [routerLinkActiveOptions]="{ exact: true }"
  class="pt-3 pb-3 color-black flex align-items-center"
>
  <i class="fa-sharp fa-solid fa-location-dot mr-4"></i>
  <span class="ml-1 color-black">{{ "NAVIGATION.my_trips" | translate }}</span>
</a>
<a
  [routerLink]="
    !_sidebarPrivateService.isSidebarPrivate$.value
      ? ['my-favorites']
      : ['profile/my-favorites']
  "
  [routerLinkActive]="'is-active'"
  class="pt-3 pb-3 color-black flex align-items-center"
>
  <i class="fa-solid fa-heart mr-4"></i>
  <span class="ml-1 color-black">{{
    "NAVIGATION.my_favorites" | translate
  }}</span>
</a>
<a
  [routerLink]="
    !_sidebarPrivateService.isSidebarPrivate$.value
      ? ['my-activity']
      : ['profile/my-activity']
  "
  [routerLinkActive]="'is-active'"
  class="pt-3 pb-3 color-black flex align-items-center"
>
  <i class="fa-solid fa-clock-rotate-left mr-4"></i>
  <span class="ml-1 color-black">{{
    "NAVIGATION.my_activity" | translate
  }}</span>
</a>
<a
  [routerLink]="
    !_sidebarPrivateService.isSidebarPrivate$.value
      ? ['membership']
      : ['profile/membership']
  "
  [routerLinkActive]="'is-active'"
  class="pt-3 pb-3 color-black flex align-items-center"
>
  <i class="fa-solid fa-star mr-4"></i>
  <span class="ml-1 color-black">{{
    "NAVIGATION.membership" | translate
  }}</span>
</a>
<a
  [routerLink]="
    !_sidebarPrivateService.isSidebarPrivate$.value
      ? ['my-account']
      : ['profile/my-account']
  "
  [routerLinkActive]="'is-active'"
  class="pt-3 pb-3 color-black flex align-items-center"
>
  <i class="fa-solid fa-circle-user mr-4"></i>
  <span class="ml-1 color-black">{{
    "NAVIGATION.my_account" | translate
  }}</span>
</a>
