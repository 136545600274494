export const environment = {
  production: true,
  hmr: false,
  apiUrl: 'https://api-dev.travelaya.com',
  baseUrl: 'https://dev.travelaya.com',
  stripe_pk:
    'pk_test_51NhxO2DDR5mrx22La66MxTRDHX1yNK1zLhJH0Vq4733eIkaces9U9Zz5eY5Bp7AO3HRq36w3eA1E3CloxeqYy46b00S10TMQP5',
  publicKeyCrypto: '67267862378678hjv2hv5hv36b276cbb623',
  storeKeys: {
    USER_LANGUAGE: 'user_language',
    ONBOARDING: 'onboarding',
    DESTINATION: 'destination',
    FORGOT: 'forgot',
    HOTEL_SELECTED: 'hotel_selected',
    ROOMS_SELECTED: 'rooms_selected',
    BOOKING_CONFIRM: 'booking_confirm',
    RESERVATION_CONFIRM: 'reservation_confirm',
    CARD: 'card',
    UUID: 'uuid',
    ACCESS: 'access',
    SESSION: 'session',
    SELECTED_DESTINY: 'selected_destiny',
    CODE: 'code',
  },
  languages: ['es', 'en', 'de', 'fr', 'it', 'pt'],
  defaultLanguage: 'en',
  selectLanguages: [
    {
      label: 'Español',
      value: 'es-ES',
      flag: './assets/icons/flg_es.svg',
      currency: 'EUR',
    },
    {
      label: 'English',
      value: 'en-EN',
      flag: './assets/icons/flg_en.svg',
      currency: 'USD',
    },
    {
      label: 'Deutsch',
      value: 'de-DE',
      flag: './assets/icons/flg_de.svg',
      currency: 'EUR',
    },
    {
      label: 'French',
      value: 'fr-FR',
      flag: './assets/icons/flg_fr.svg',
      currency: 'EUR',
    },
    {
      label: 'Italian',
      value: 'it-IT',
      flag: './assets/icons/flg_it.svg',
      currency: 'EUR',
    },
    {
      label: 'Portuguese',
      value: 'pt-PT',
      flag: './assets/icons/flg_pt.svg',
      currency: 'EUR',
    },
  ],
  defaultSelectLanguage: {
    label: 'English',
    value: 'en-EN',
    flag: './assets/icons/flg_en.svg',
  },
};
