<div class="flex flex-row justify-content-center align-items-center">
  <p-card
    class="check_inbox"
    header="{{ 'SIGNUP.CHECK_INBOX.title' | translate }}">
    <ng-template pTemplate="header">
      <img
        alt="check_inbox"
        src="assets/images/check_inbox.svg"
        width="85.4px"
        height="55.2px" />
    </ng-template>

    <!-- <div class="text-center mb-4">
      <p>{{ 'SIGNUP.CHECK_INBOX.subtitle' | translate }}</p>
    </div> -->

    <div class="field">
      <span class="p-input-icon-right">
        <input
          [(ngModel)]="code"
          type="text"
          pInputText
          placeholder="{{ 'SIGNUP.CHECK_INBOX.code' | translate }}" />
      </span>
    </div>

    <div class="field">
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'GENERAL.btn_continue' | translate }}"
        class="p-button-rounded p-button-warning primary flex justify-content-center w-full"
        [disabled]="code == ''"
        (click)="onContinue()"></button>
    </div>

    <div class="text-center">
      <a class="color-primary underline" (click)="sendNewCode()">
        {{ 'SIGNUP.CHECK_INBOX.no_recived' | translate }}</a
      >
    </div>

    <ng-template pTemplate="footer"> </ng-template>
  </p-card>
</div>
