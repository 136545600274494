import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecoverService } from 'src/app/core/providers/public/recover/recover.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { SidebarLoginSignupService } from 'src/app/core/providers/public/sidebar-login-signup/sidebar-login-signup.service';
import { GeneralService } from 'src/app/core/providers/general.service';
import { RecoverFormComponent } from './recover-form/recover-form.component';
import { CheckInboxComponent } from './check-inbox/check-inbox.component';
import { PublicNavBarComponent } from '../../shared-public/public-navbar/public-nav-bar.component';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/core/providers/infrastructure/storage.service';

@Component({
  selector: 'app-recover-pass',
  standalone: true,
  imports: [
    CommonModule,
    RecoverFormComponent,
    CheckInboxComponent,
    ToastModule,
    PublicNavBarComponent,
    RecoverFormComponent,
  ],
  templateUrl: './recover-pass.component.html',
  styleUrls: ['./recover-pass.component.scss'],
  providers: [],
})
export class RecoverPassComponent implements OnInit {
  public initRecoverPass: boolean = false;
  private storage = inject(StorageService);

  constructor(
    public _recoverService: RecoverService,
    private _messageService: MessageService,
    private _sidebarLoginSignupService: SidebarLoginSignupService,
    private _generalService: GeneralService
  ) {}

  async ngOnInit(): Promise<any> {
    this._sidebarLoginSignupService.showSidebar$.next(false);
    this.initRecoverPass = await this.storage.get(environment.storeKeys.FORGOT);
    if (!this.initRecoverPass) {
      this._recoverService.showNewPass$.next(false);
    } else {
      this._recoverService.showNewPass$.next(true);
    }
  }

  showToast(res) {
    switch (res.status) {
      case 400:
        this._messageService.add({
          severity: 'info',
          summary: 'Info',
          detail: res.message,
        });
        break;
      case 200:
      case 201:
        this._messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: res.message,
        });
    }
  }
}
