<div class="popup_cancel_trip">
  <p-card class="" header="">
    <ng-template pTemplate="header">
      <div class="">
        <img
          alt="cancel"
          ngSrc="./assets/images/cancel_trip.png"
          width="52"
          height="52"
        />
      </div>
      <h6 class="m-0 title">
        {{ "ACCOMODATIONS.hotel_confirmation.cancel_confirm" | translate }}
      </h6>
      <p class="subtitle">
        {{ "ACCOMODATIONS.hotel_confirmation.text_cancel_trip" | translate }}
      </p>
    </ng-template>
    <div
      class="col-12 flex flex-column justify-content-start align-items-center buttons"
    >
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'ACCOMODATIONS.hotel_confirmation.btn_cancel' | translate }}"
        class="p-button-rounded p-button-warning btn-yes mb-2"
        (click)="confirmCancelTrip()"
      ></button>
      <a class="btn_cancel underline" (click)="unconfirmCancelTrip()">
        {{ "ACCOMODATIONS.hotel_confirmation.txt_cancel" | translate }}
      </a>
    </div>
  </p-card>
</div>
