import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-popup-working',
  standalone: true,
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    TranslateModule
  ],
  templateUrl: './popup-working.component.html',
  styleUrls: ['./popup-working.component.scss']
})
export class PopupWorkingComponent {

}
